import React from "react"

import Layout from "@src/components/Layout"
import SplashWithIllustration from "@src/components/SplashWithIllustration"
import CommunityCallout from "@src/components/CommunityCallout"
import Callout from "@src/components/Callout"
import getAppUrl from "@src/utils/getAppUrl"
import Section from "@src/components/Section"
import FeatureGrid from "@src/components/FeatureGrid"
import track from "@src/utils/track"

import APIPackIcon from "@src/icons/v2/startup-pack/api-pack.svg"
import AutomateIcon from "@src/icons/v2/startup-pack/automate-workflows.svg"
import CodeIcon from "@src/icons/v2/startup-pack/code-editor.svg"
import IncreaseIcon from "@src/icons/v2/startup-pack/increase-roi.svg"
import ManageIcon from "@src/icons/v2/startup-pack/manage-all-tools.svg"
import FormsIcon from "@src/icons/v2/startup-pack/forms.svg"
import NotificationsIcon from "@src/icons/v2/startup-pack/notifications.svg"
import NotesIcon from "@src/icons/v2/startup-pack/release-notes.svg"
import RoadmapIcon from "@src/icons/v2/startup-pack/roadmap.svg"
import Integrations from "@src/icons/screenshots/integrations.png"
import OG from "@src/icons/og/OG_Saas_Pack.jpeg"

import * as styles from "./styles.module.scss"
import Splash from "./components/Splash"
import Row from "../components/Row"
import Features from "./components/Features"
import ImageRow from "./components/ImageRow"

const IndexPage = ({ location }) => {
  const mainAppUrl = getAppUrl(location, "/signup")
  return (
    <Layout
      title="SaaS Startup Toolkit by Canonic"
      description="Build public roadmaps, release logs, dynamic website notifications, and other internal tools without a backend."
      url="https://canonic.dev/features/saas-api-pack"
      location={location}
      ogImage={OG}
    >
      <Splash
        title="The SaaS API Pack"
        subtitle="Build public roadmaps, release logs, dynamic website notifications, and other internal tools without a backend."
        illustrationComponent={<APIPackIcon />}
      />
      <Row>
        <ImageRow
          title="All that a SaaS startup needs"
          subtitle="With Canonic's low-code platform and the production-ready clone and go project, you can deploy ready-to-integrate APIs in minutes."
          screenshot={Integrations}
        />
        <Features
          numPerRow={2}
          horizontal
          features={[
            {
              icon: ManageIcon,
              title: "Manage all your tools in one place",
              subtitle: (
                <>
                  Marketing can take complete ownership, and make changes and
                  updates using Canonic's intuitive <strong>CMS.</strong>
                </>
              ),
            },
            {
              icon: AutomateIcon,
              title: "Automate using workflows",
              subtitle: (
                <>
                  Whether it's <strong>notifying the team on Slack</strong> or
                  sending a <strong>Thank You email</strong> when collecting
                  feedback.
                </>
              ),
            },
            {
              icon: CodeIcon,
              title: "A code editor for your custom logic",
              subtitle: (
                <>
                  Think of this pack as just a boilerplate. You can{" "}
                  <strong>build and extend the functionality</strong> and grow
                  it with your company.
                </>
              ),
            },
            {
              icon: IncreaseIcon,
              title: "Increase ROI",
              subtitle: (
                <>
                  <a href="https://medium.com/@canonicHQ/raise-your-roi-with-lowcode-platforms-a71101bac03f">
                    Low-code Substantially lowers costs and effort
                  </a>{" "}
                  which leads to an{" "}
                  <strong>incredibly faster speed-to-value.</strong> No
                  engineering bandwidth needed.
                </>
              ),
            },
          ]}
        />
      </Row>
      <Row>
        <h2 className={styles.title}>APIs built to increase user engagement</h2>
        <p className={styles.subtitle}>Tools in this pack</p>
        <Features
          numPerRow={4}
          features={[
            {
              icon: RoadmapIcon,
              title: "Public Roadmap",
              link: {
                title: "Learn More",
                url: "https://dev.to/canonic/how-to-build-a-customer-facing-roadmap-with-react-1gal",
              },
              subtitle: (
                <>
                  Have you ever wondered if your{" "}
                  <a href="https://medium.com/@canonicHQ/should-your-roadmap-be-public-1cda23e6daf0">
                    roadmap should be public?
                  </a>{" "}
                  We think it should! Create a public roadmap, build trust and
                  get early feedback!
                </>
              ),
            },
            {
              icon: NotesIcon,
              title: "Release Notes",
              link: {
                title: "Learn More",
                url: "https://dev.to/canonic/publish-changelog-for-your-product-using-react-and-tailwind-nmn",
              },
              subtitle: (
                <>
                  <strong>Transparency is paramount for SaaS startups.</strong>{" "}
                  Keep your users informed and engaged by giving them regular
                  updates on your releases.
                </>
              ),
            },
            {
              icon: NotificationsIcon,
              title: "Dynamic Popups & Alerts",
              link: {
                title: "Learn More",
                url: "https://dev.to/canonic/how-to-add-a-notification-banner-or-popup-to-your-website-3kmf",
              },
              subtitle: (
                <>
                  Show dynamic notifications, alerts, and bars on your website
                  that can be{" "}
                  <strong>
                    independently controlled using business logic and the CMS
                  </strong>{" "}
                  without engineering effort.
                </>
              ),
            },
            {
              icon: FormsIcon,
              title: "Forms to collect feedback",
              link: {
                title: "Learn More",
                url: "https://dev.to/canonic/build-request-forms-using-react-ant-design-and-a-lowcode-backend-26oe",
              },
              subtitle: (
                <>
                  Use canonic to collect data using forms. Integrate with Slack,
                  Twilio, and other services to{" "}
                  <strong>automate replies, notifications, and more.</strong>
                </>
              ),
            },
          ]}
        />
      </Row>
      <CommunityCallout />
    </Layout>
  )
}

export default IndexPage
